<div class="position-relative">
  <div
    *ngIf="suscription_selected"
    class="flag"
    style="padding: 40px 20px"
    [style.backgroundColor]="validate_color(suscription_selected.id)"
  ></div>
</div>

@if (suscription_selected) {
  <div class="row animated fadeInRight">
    <div class="col-12 text-start">
      <b>
        Detalles de la Membresía:
        <b
          class="badge"
          [class]="
            status_suscription === 'active' ? 'bg-success' : 'bg-warning'
          "
          >{{ status_suscription }}</b
        ></b
      >
      <ul>
        <li>Plan Seleccionado: {{ suscription_selected.title }}</li>
        <li>Precio: $ {{ suscription_selected.price | number }} + intereses</li>
        <li>
          Límite de Personas:
          {{
            suscription_selected.limit
              ? suscription_selected.limit
              : "Sin limite"
          }}
        </li>
      </ul>
      Por favor, completa los siguientes campos con la información de tu tarjeta
      de crédito o débito para proceder con el pago de tu membresía. Tu
      información será tratada de forma segura y confidencial.
    </div>
  </div>
}

@if (user?.is_free_user) {
  <div class="row animated fadeInRight">
    <div class="col-12 text-start">
      Detalles de la Membresía: <b>Plan gratuito </b>
      <br />
      Por favor, completa los siguientes campos con la información de tu tarjeta
      de crédito o débito para proceder con el pago de tu membresía. Tu
      información será tratada de forma segura y confidencial.
    </div>
  </div>
}
@if (suscription_selected || user?.is_free_user) {
  <div class="row">
    <div class="col-12 d-flex justify-content-end align-items-center mt-4">
      @if (loading_payments) {
        <div class="d-flex justify-content-center align-items-center">
          Cargando tarjetas
          <div class="spinner-border" role="status">
            <span class="visually-hidden">...</span>
          </div>
        </div>
      }
      @if (cardsList.length > 0) {
        <button class="btn btn-dark" (click)="changeView()">
          {{
            new_card ? "Agregar nueva tarjeta" : "Lista de tarjetas guardadas"
          }}
        </button>
      }
    </div>
    <div class="col-12">
      <div
        class="animated fadeIn"
        [class]="!new_card ? 'py-5' : 'py-0'"
        style="height: 430px; overflow: auto"
      >
        @if (!new_card) {
          <div class="d-flex justify-content-center align-items-center">
            <div class="tarjeta" id="tarjeta">
              <div class="delantera">
                <div class="logo-marca" id="logo-marca">
                  <img
                    class="img-fluid"
                    src="assets/images/logo.png"
                    alt="logo"
                    style="max-width: 122px"
                  />
                </div>
                <img
                  src="assets/images/card/chip-tarjeta.png"
                  class="chip"
                  alt="chip"
                />
                <form [formGroup]="card">
                  <div class="datos">
                    <div class="grupo">
                      @if (elementsOptions.clientSecret) {
                        <ngx-stripe-card
                          [stripe]="stripe"
                          [options]="cardOptions"
                          [elementsOptions]="elementsOptions"
                        >
                        </ngx-stripe-card>
                      }
                    </div>
                    <div class="flexbox">
                      <div class="grupo">
                        <input
                          class="form-control input-name"
                          type="text"
                          formControlName="holder"
                          placeholder="Ingresa el nombre de la tarjeta"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        } @else {
          @if (cardsList.length > 0) {
            <h3 class="mb-3">Mis tarjetas</h3>

            <div
              class="d-flex justify-content-center align-items-center flex-wrap gap-3"
            >
              @for (item of cardsList; track item.id; let idx = $index) {
                <div
                  class="card mt-2"
                  style="font-size: 12px; width: 400px"
                  [ngClass]="{ selected: item.default === 'True' }"
                >
                  <div class="d-flex justify-content-between w-100">
                    <div
                      style="width: 90%"
                      class="card-payment p-2 card-payment-hover"
                      (click)="set_as_default(item, idx)"
                    >
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        {{ item.card?.brand }}
                      </div>
                      <div>
                        <span
                          >Terminada en <b>{{ item.card?.last4 }}</b></span
                        ><br />
                        <span
                          >Vencimiento:
                          <b
                            >{{ item.card?.exp_month }}/{{
                              item.card?.exp_year
                            }}</b
                          ></span
                        >
                      </div>
                    </div>
                    <div
                      class="d-flex justify-content-center align-items-center"
                      style="width: 10%"
                    >
                      <em
                        class="fa fa-trash text-danger pointer"
                        style="font-size: 30px"
                        (click)="deletePayments(item)"
                      ></em>
                    </div>
                  </div>
                </div>
              }
            </div>
          } @else {
            <p>No hay targetas activas</p>
          }
        }
      </div>
      <div class="row">
        <small class="mb-5">
          Nota: Tu membresía se renovará automáticamente al final de cada
          período de facturación, a menos que canceles la suscripción con
          anticipación. Puedes gestionar tu suscripción y actualizar tus datos
          de pago en cualquier momento desde tu cuenta.
        </small>
        <div class="col-12 mr-lg-5 d-flex justify-content-center">
          <button class="btn btn-dark" (click)="submitCard()">
            @if (loading_submit) {
              <div class="d-flex justify-content-center align-items-center">
                Cargando información
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">...</span>
                </div>
              </div>
            } @else {
              Guardar información
            }
          </button>
        </div>
      </div>
    </div>
  </div>
}
