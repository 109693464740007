import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxStripeModule } from 'ngx-stripe';
import { CardComponent } from './card/card';
import { environment } from '../../../environments/environment';
import { ReferenceComponent } from './reference/reference';
import { Step2Component } from '../pages/register/step2/step2';
import { RegisterComponent } from '../pages/register/register';
import { Step1Component } from '../pages/register/step1/step1';
import { Step3Component } from '../pages/register/step3/step3';
import { Step4Component } from '../pages/register/step4/step4';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { NgxGraphComponent } from './ngx-graph/ngx-graph';
import { FormatPhonePipe } from '../../core/pipe/phone.pipe';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropperComponent } from './image-cropper/image-cropper';
import { PaginationComponent } from './pagination/pagination.component';
import { CarouselAboutComponent } from './carousel-about/carousel-about';

@NgModule({
  declarations: [
    CardComponent,
    ReferenceComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component,
    RegisterComponent,
    NgxGraphComponent,
    FormatPhonePipe,
    ImageCropperComponent,
    PaginationComponent,
    CarouselAboutComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxGraphModule,
    FormsModule,
    ImageCropperModule,
    NgxStripeModule.forRoot(environment.PK_STRIPE),
  ],
  exports: [
    CardComponent,
    ReferenceComponent,
    Step2Component,
    Step1Component,
    Step2Component,
    Step3Component,
    Step4Component,
    RegisterComponent,
    NgxGraphComponent,
    FormatPhonePipe,
    ImageCropperComponent,
    PaginationComponent,
    CarouselAboutComponent,
  ],
  providers: [],
})
export class ComponentsModule {}
