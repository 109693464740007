import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class FormatPhonePipe implements PipeTransform {
  transform(telefono: string | undefined): string {
    // Eliminar cualquier carácter que no sea un dígito
    if (telefono) {
      let numeroLimpio = telefono.replace(/\D/g, '');

      // Aplicar el formato deseado
      let numeroFormateado = numeroLimpio.replace(
        /(\d{2})(\d{4})(\d{2})(\d{2})/,
        '+$1 $2 $3 $4',
      );
      return numeroFormateado;
    }
    return telefono ? telefono : '';
  }
}
