export interface suscription {
  id: 0 | 1 | 2;
  title: 'Plata' | 'Oro' | 'Platino';
  price: number;
  limit: number | '';
  description: string;
}
export const suscriptions: suscription[] = [
  {
    id: 0,
    title: 'Plata',
    price: 200,
    limit: 100,
    description:
      'Ideal para grupos pequeños o comunidades con un límite de 100 personas. Acceso completo a nuestra plataforma a un precio accesible',
  },
  {
    id: 1,
    title: 'Oro',
    price: 500,
    limit: 200,
    description:
      'Una opción robusta y flexible para grupos medianos o empresas en crecimiento. Acceso completo con un límite de 200 personas.',
  },
  {
    id: 2,
    title: 'Platino',
    price: 1000,
    limit: '',
    description:
      'La elección definitiva para empresas grandes o aquellos que buscan lo mejor. Acceso completo a todas las funciones premium sin límite de personas.',
  },
];
