<div class="row">
  @if (showCropp) {
    <div class="col-12">
      <div class="settings-menu">
        <em
          [class]="
            containWithinAspectRatio ? 'fa fa-arrows-alt' : 'fa fa-expand'
          "
          (click)="toggleContainWithinAspectRatio()"
        ></em>
        <em class="fa fa-undo" (click)="rotateLeft()"></em>
        <em class="fa fa-undo voltear" (click)="rotateRight()"></em>
        <em class="fa fa-sort horizontal" (click)="flipHorizontal()"></em>
        <em class="fa fa-sort" (click)="flipVertical()"></em>
        <em class="fa fa-search-minus" (click)="zoomOut()"></em>
        <em class="fa fa-search-plus" (click)="zoomIn()"></em>
      </div>
    </div>
  }
  <div
    [class]="showCropp ? 'col-6' : 'col-12'"
    class="d-flex justify-content-center align-items-center flex-column"
  >
    <label
      for="labelLogo"
      [class]="size == 1 ? 'label-size-1' : 'label-size-2'"
    >
      <img
        [src]="preview && !url ? preview : returnImage()"
        class="img-fluid img-thumbnail"
        [class]="rounded ? 'rounded-circle' : ''"
        alt="logo"
      />
    </label>
    <input
      id="labelLogo"
      (change)="fileChangeListener($event)"
      accept=".png, .jpg, .jpeg"
      type="file"
      style="display: none"
    />
    <span>Has click en la imagen para añadir una nueva</span>
  </div>
  <div [class]="showCropp ? 'col-6' : 'col-12 d-none'">
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="false"
      [canvasRotation]="canvasRotation"
      [transform]="transform"
      [imageBase64]="imageBase64"
      [style.display]="showCropp ? null : 'none'"
      [containWithinAspectRatio]="containWithinAspectRatio"
      [aspectRatio]="aspectRatio"
      format="png"
      (imageLoaded)="imageLoaded()"
      (LoadedImage)="LoadedImage($event)"
      [onlyScaleDown]="false"
      [alignImage]="'left'"
      [roundCropper]="roundCropper"
      (imageCropped)="imageCropped($event)"
      (cropperReady)="cropperReady($event)"
      (loadImageFailed)="loadImageFailed()"
      [cropperStaticHeight]="cropperStaticHeight"
      [cropperStaticWidth]="cropperStaticWidth"
    >
    </image-cropper>
    <div *ngIf="!imageChangedEvent" class="text-center">
      <small>Click para visualizar la imagen.</small>
    </div>
  </div>
</div>
