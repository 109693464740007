import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { suscription } from '../const';
import { User } from '../../../../core/interfaces/interfaces.interfaces';
import { Router } from '@angular/router';
import { GlobalFunctionService } from '../../../services/function.service';

@Component({
  selector: 'app-step-4',
  templateUrl: './step4.html',
  styleUrls: ['../register.scss'],
})
export class Step4Component implements OnInit {
  @Output() submit = new EventEmitter<{ step: 4 }>();
  @Input() user: User | null = null;
  @Input() suscription_selected: suscription | undefined = undefined;

  constructor(
    public route: Router,
    public functions: GlobalFunctionService,
  ) {}

  ngOnInit(): void {}

  submitForm() {
    this.submit.emit({ step: 4 });
    this.route.navigateByUrl('/client/inicio');

    this.functions.alertAnimation(
      'success',
      'Se ha guardado correctamente',
      'Enhorabuena',
    );
  }
}
