<div class="text-center" *ngIf="reference">
  <div class="my-5">
    <b>
      Por favor, utiliza el siguiente código de referencia único para
      referenciar tu cuenta.
    </b>

    <div
      class="d-flex justify-content-center align-items-center pointer"
      (click)="copyText(reference.code)"
    >
      <h2 class="my-5 code">
        {{ reference.code }}
      </h2>
      <em
        class="fa fa-2x"
        [class]="text ? 'fa-check text-success' : 'fa-files-o'"
      ></em>
    </div>
    Este es el código de referencia actual. <br />
    Si necesitas generar uno nuevo, simplemente haz clic en el siguiente linea
    <p class="pointer" (click)="generateCode()">
      <a class="link-opacity-10 text-dark">Generar Nuevo Código</a>
    </p>
  </div>
</div>
