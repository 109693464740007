import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Output('event') event = new EventEmitter();
  @Input() total: number = 0;
  @Input() pageSize: number = 30;

  items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  default = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  active: any = 1;
  btnNext = false;
  constructor() {}
  ngDoCheck(): void {
    this.validatelimit(this.total);
    this.validateTotal(this.total);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['total'] && changes['total'].currentValue >= 0) {
      if (changes['total'].currentValue !== changes['total'].previousValue) {
        this.active = 1;
      }
    }
  }

  ngOnInit() {}

  validatelimit(total: number) {
    this.default = [];
    let div = total / this.pageSize;
    for (let i = 1; i < div; i++) {
      this.default.push(i + 1);
    }

    if (this.default.length > 10) {
      let array = this.default.slice(0, 10);
      this.default = array;
    }
    if (this.active > 10) {
      for (let i = 0; i < div; i++) {
        this.default.push(i + 1);
      }
      let array = this.default.slice(this.active, this.active + 10);
      this.default = array;
      this.active !== Math.ceil(div) && this.default.push(Math.ceil(div));
    }
    this.default.unshift(1);
  }
  getIndex(index: number) {
    this.active = index;
    this.event.emit(this.active);
  }
  previuspage() {
    this.validatelimit(this.total);
    this.validateTotal(this.total);
    if (this.active >= 2) {
      this.active--;
      let min = Math.min(...this.default);
      if (min === this.active) {
        this.default.push(min - 1);
        this.default = this.default.sort(function (a, b) {
          return a - b;
        });
        this.default.splice(this.default.length - 1, 1);
      }
      this.event.emit(this.active);
    }
  }
  nextPage() {
    let number = Math.ceil(this.total / this.pageSize);

    if (number !== this.active) {
      this.active++;
      this.active > this.default[this.default.length - 1] &&
        (this.default.splice(0, 1),
        this.default.push(this.default[this.default.length - 1] + 1));

      this.event.emit(this.active);
    }
    this.validatelimit(this.total);
    this.validateTotal(this.total);
  }
  validateTotal(total: number) {
    let totalPages = Math.ceil(total / this.pageSize);
    return this.active === totalPages;
  }
}
