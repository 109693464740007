import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { suscription, suscriptions } from '../const';
import { GlobalFunctionService } from '../../../services/function.service';
import { PaymentService } from '../../../services/payment.service';
@Component({
  selector: 'app-step-2',
  templateUrl: './step2.html',
  styleUrls: ['../register.scss'],
})
export class Step2Component implements OnInit {
  @Output() submit = new EventEmitter<{
    step: 2;
    suscription: suscription | undefined;
  }>();
  @Input() suscription: suscription | undefined;
  suscriptions: suscription[] = suscriptions;
  constructor(
    public functions: GlobalFunctionService,
    public paymentService: PaymentService,
  ) {}

  ngOnInit(): void {
    this.paymentService.get_tier_data().subscribe((tiers) => {
      this.suscriptions.forEach((resp, idx) => {
        const find = tiers.find(
          (item) => item.name.toLowerCase() === resp.title.toLowerCase(),
        );
        if (find) {
          this.suscriptions[idx] = {
            ...this.suscriptions[idx],
            price: find.price_with_decimal,
          };
        }
      });
    });
  }

  submitForm(suscription: suscription | undefined) {
    this.submit.emit({ step: 2, suscription });
  }
  validate_color(plan: 0 | 1 | 2 | '') {
    return this.functions.validate_color(plan);
  }
}
