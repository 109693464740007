import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Edge, Node, Layout, DagreNodesOnlyLayout } from '@swimlane/ngx-graph';
import * as shape from 'd3-shape';
import { Subject } from 'rxjs';
import { User, network } from '../../../core/interfaces/interfaces.interfaces';

@Component({
  selector: 'app-ngx-graph',
  templateUrl: './ngx-graph.html',
  styleUrls: ['./ngx-graph.scss'],
})
export class NgxGraphComponent implements OnInit {
  public layoutSettings = {
    orientation: 'TB',
  };
  public curve: any = shape.curveLinear;
  public layout: Layout | string = '';
  public nodes: Node[] = [];
  public links: Edge[] = [];
  colorSchema = [
    {
      domain: ['#bf9d76', '#e99450', '#d89f59'],
    },
    {
      domain: ['#a8385d', '#7aa3e5', '#a27ea8'],
    },
  ];
  @Input() myNetwork: network[] = [];
  @Input() user: User | null = null;
  @Input() count_subclient = 0;
  autoCenter: boolean = true;
  center$: Subject<boolean> = new Subject();
  update$: Subject<boolean> = new Subject();
  /*   employees: any[] = [
        {
          id: "1",
          name: "Employee 1",
        },
        {
          id: "2",
          name: "Employee 2",
          upperManagerId: "1",
        },
        {
          id: "3",
          name: "Employee 3",
          upperManagerId: "1",
        },
        {
          id: "4",
          name: "Employee 4",
          upperManagerId: "1",
        },
      ]; */

  constructor() {}
  /*  ngOnChanges(changes: SimpleChanges): void {
    if (changes['groups']) this.generate();
  } */
  public ngOnInit(): void {
    this.generate();
  }

  generate = async () => {
    this.layout = new DagreNodesOnlyLayout();
    if (this.user) {
      let groups = this.myNetwork.map((item: any, idx) => {
        let obj = {
          id: (idx + 2).toString(),
          name: item.user.first_name,
          data: { ...item, users_length: 0 },
        };
        return idx === 0 ? obj : { ...obj, upperManagerId: '2' };
      });

      groups.unshift({
        id: '1',
        name: this.user?.first_name,
        data: { ...this.user, users_length: this.myNetwork.length },
      });
      this.nodes = groups.map((item) => {
        return {
          id: item.id,
          label: item.name,
          data: item.data,
        };
      });

      let link: any = [...groups].splice(1);
      this.links = link.map((employee: any) => {
        return {
          source: '1',
          target: employee.id,
        };
      });
    }
    await this.update$.next(true);
    await this.center$.next(true);
  };

  validateHeight(data: any) {
    return 220;
  }
  clickClient(body: any, client: any) {}

  validateNameFarms = (data: any) => {
    return data.farms.map((item: any) => item.name);
  };
  clients(node: any) {
    return 'hola';
  }
}
