<div class="text-center">
  <h1>¡Selecciona un plan!</h1>
  <hr class="mb-5 mt-5" />
</div>
<div class="d-flex align-items-center justify-content-center gap-3 flex-wrap">
  @for (item of suscriptions; track item.id) {
    <div class="plan text-center position-relative">
      <div class="flag" [style.backgroundColor]="validate_color(item.id)"></div>
      <div class="d-flex flex-column">
        <h2>{{ item.title }}</h2>
        <h3>${{ item.price | number }}</h3>
        <p>
          {{ item.limit ? "Limite de " + item.limit : "Sin límite de" }}
          personas
        </p>

        <small class="mt-3">{{ item.description }}</small>
      </div>
      <button
        class="btn w-100 mt-5"
        [class]="
          suscription && item.id === suscription.id
            ? 'btn-success text-white'
            : 'btn-dark'
        "
        (click)="submitForm(item)"
      >
        Obtener
      </button>
    </div>
  } @empty {
    No hay planes disponibles
  }
</div>
