import { Component, Input, OnInit } from '@angular/core';
import { reference } from '../../../core/interfaces/interfaces.interfaces';
import { GlobalFunctionService } from '../../services/function.service';
import { UsersService } from '../../services/users.service';

@Component({
  selector: 'app-reference-code',
  templateUrl: './reference.html',
  styleUrls: ['./reference.scss'],
})
export class ReferenceComponent implements OnInit {
  @Input() reference: reference = {
    code: '',
    created_at: '',
  };
  text = false;
  constructor(
    public functions: GlobalFunctionService,
    public userService: UsersService,
  ) {}

  ngOnInit(): void {}

  copyText(text: string) {
    this.text = true;
    this.functions.copyText(text);
    setTimeout(() => {
      this.text = false;
    }, 5000);
  }

  generateCode() {
    this.userService.generateCodeUser().subscribe((resp) => {
      this.reference = resp;
    });
  }
}
