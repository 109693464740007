<div
  class="w-100 d-flex align-items-center gap-2"
  [class]="
    list.length > items ? 'justify-content-between' : 'justify-content-center'
  "
>
  @if (loading_animation && list.length > items) {
    <div class="icon-menu">
      <i (click)="previous()" class="fa fa-angle-left" aria-hidden="true"></i>
    </div>
  }
  <div
    class="middle d-flex align-items-center"
    style="gap: 60px"
    id="middle"
    #middle
  >
    <div class="content-carousel">
      @for (item of temporal; track item.id) {
        <div class="card content-card" >
          <p class="mt-2 p-4 mb-0">{{ item.description }}</p>
          <div class="w-100 d-flex px-4 py-2">
            <div>
              <img
                #iconRef
                class="img-fluid rounded-circle border-1"
                [src]="item.image"
                style="width: 50px; height: 50px"
                [alt]="item.name"
                (error)="iconRef.src = 'assets/images/default.png'"
              />
            </div>
            <div>
              <small class="m-0">
                {{ item.name }}
              </small>
              <div class="d-flex flex-wrap gap-2">
                <em class="fa fa-star text-warning"></em>
                <em class="fa fa-star text-warning"></em>
                <em class="fa fa-star text-warning"></em>
                <em class="fa fa-star text-warning"></em>
                <em class="fa fa-star text-warning"></em>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
  @if (loading_animation && list.length > items) {
    <div class="icon-menu">
      <i (click)="next()" class="fa fa-angle-right" aria-hidden="true"></i>
    </div>
  }
</div>
<!-- <div class="d-flex justify-content-center gap-5 mt-4">
  <button class="btn btn-light" (click)="previous()">
    <i class="fa fa-angle-left" aria-hidden="true"></i> Anterior
  </button>
  <button class="btn btn-light" (click)="next()">
    Siguiente <i class="fa fa-angle-right" aria-hidden="true"></i>
  </button>
</div>
 -->
