import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NewUser, User } from '../../../core/interfaces/interfaces.interfaces';
import { UsersService } from '../../services/users.service';
import { suscription } from './const';
import { GlobalFunctionService } from '../../services/function.service';
import { AuthService } from '../../services/auth.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.html',
  styleUrls: ['./register.scss'],
})
export class RegisterComponent implements OnInit {
  @Input() code: string = '';
  @Input() is_admin = false;
  step: 1 | 2 | 3 | 4 = 1;
  steps: Array<1 | 2 | 3 | 4> = [1, 2, 3, 4];
  @Output() submitCreateClient = new EventEmitter();
  suscription: suscription | undefined = undefined;
  user: NewUser | undefined = undefined;
  user_loged: User | null = null;
  constructor(
    public userService: UsersService,
    public functions: GlobalFunctionService,
    public authService: AuthService,
  ) {}

  ngOnInit(): void {
    if (!this.code && !this.is_admin) {
      localStorage.removeItem('token');
    }
  }
  submitForm = async ({
    user,
    step,
    suscription,
  }: {
    step: 1 | 2 | 3 | 4;
    suscription?: suscription | undefined;
    user?: NewUser | undefined;
  }) => {
    /*     this.step = event.step; */
    const validateCode = () => {
      if (this.code) {
        return this.code;
      }
      if (this.user?.code) {
        return this.user?.code;
      }

      return '';
    };
    const validate_plan = (id: number): 'plata' | 'oro' | 'platino' => {
      if (id === 0) return 'plata';
      if (id === 1) return 'oro';
      return 'platino';
    };
    if (step === 1) {
      console.log(this.is_admin, this.user);
      if (this.is_admin && user && user.is_free_user) {
        delete user?.is_free_user;
        const refeed = await lastValueFrom(
          this.userService.createUserRefedFreeAdmin({
            code: validateCode(),
            account_plan: 'gratis',
            user: user,
          }),
        );
        if (refeed) {
          this.submitCreateClient.emit();
          this.functions.alertAnimation(
            'success',
            'Se ha registrado el usuario exitosamente',
            'Enhorabuena',
          );
        }
        this.step = 3;
      } else {
        this.step = 2;
        delete user?.is_free_user;

        if (user) {
          this.user = user;
        }
      }
    }
    if (step === 2) {
      this.suscription = suscription;
      if (this.is_admin && this.user && suscription) {
        const refeed = await lastValueFrom(
          this.userService.createUserRefed({
            code: validateCode(),
            account_plan: validate_plan(suscription.id),
            user: this.user,
          }),
        );
        if (refeed) {
          this.submitCreateClient.emit();
          this.functions.alertAnimation(
            'success',
            'Se ha registrado el usuario exitosamente',
            'Enhorabuena',
          );
        }
      }
      this.step = 3;
    }
    if (step === 3) {
      delete user?.code;
      if (this.suscription && this.user) {
        const user = this.user;
        try {
          const refeed = await lastValueFrom(
            this.userService.createUserRefed({
              code: validateCode(),
              account_plan: validate_plan(this.suscription?.id),
              user,
            }),
          );
          if (refeed) {
            if (!this.code) {
              this.step = 4;
              this.functions.alertAnimation(
                'success',
                'Se ha registrado tu usuario exitosamente',
                'Enhorabuena',
              );
              const login = await lastValueFrom(
                this.authService.login({
                  email: user.email,
                  password: user.password,
                }),
              );
              if (login) {
                this.user_loged = login.user;
              }
            } else {
              this.functions.alertAnimation(
                'success',
                'Se ha registrado el usuario exitosamente',
                'Enhorabuena',
              );
              this.submitCreateClient.emit();
            }
          }
        } catch (error) {}
      }
    }
  };
  validateDisabledCheck(step: 1 | 2 | 3 | 4) {
    if (this.user_loged) {
      return true;
    }
    if (step === 2 && !this.user) {
      return true;
    }
    if (step === 3 && (!this.user || !this.suscription)) {
      return true;
    }
    if (step === 4 && (!this.suscription || !this.user)) {
      return true;
    }
    return false;
  }
}
