<div
  class="d-flex flex-row flex-wrap justify-content-center align-items-center gap-5 p-2 p-md-5"
>
  <img
    style="width: 200px"
    class="img-fluid"
    src="assets/images/logo_green.png"
    alt="make-money-logo"
  />
  <div class="text-register">
    <h2>¡Regístrate ya!</h2>
    <p>
      ¡Únete a la revolución digital y lleva tu experiencia en línea al
      siguiente nivel!
    </p>
  </div>
</div>
<form [formGroup]="userForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <!--  <div class="col-12 col-md-6 mt-3">
      <label for="username">Nombre de usuario</label>
      <input
        type="text"
        formControlName="username"
        class="form-control"
        id="username"
      />
    </div> -->
    <div class="col-12 col-md-7 mt-3">
      <label for="email">Correo electrónico:</label>
      <input
        type="text"
        formControlName="email"
        class="form-control"
        id="email"
      />
      <span
        class="text-danger"
        *ngIf="
          userForm.controls['email'].errors &&
          userForm.controls['email'].touched
        "
      >
        El correo electrónico no es válido
      </span>
    </div> 
    @if (is_admin) {
      <div class="col-12 col-md-5 mt-3 d-flex align-items-center">
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="is_free_user"
            formControlName="is_free_user"
          />
          <label class="form-check-label" for="is_free_user">
            ¿Este usuario usará una cuenta gratuita?</label
          >
        </div>
      </div>
    }
    <div class="col-12 col-md-6 mt-3">
      <label for="password">Contraseña:</label>
      <input
        [type]="view_password ? 'text' : 'password'"
        formControlName="password"
        class="form-control"
        id="password"
      />
      <span
        class="text-danger"
        *ngIf="
          userForm.controls['password'].errors &&
          userForm.controls['password'].touched
        "
      >
        La contraseña debe tener al menos 8 caracteres
      </span>
    </div>
    <div class="col-12 col-md-6 mt-3">
      <label for="confirmPassword">Repite la contraseña:</label>

      <div class="position-relative">
        <input
          [type]="view_password ? 'text' : 'password'"
          class="form-control"
          id="confirmPassword"
          formControlName="confirmPassword"
        />
        <em
          class="fa position-eye"
          [class]="view_password ? 'fa-eye-slash' : 'fa-eye'"
          (click)="view_password = !view_password"
        ></em>
      </div>

      <span
        class="text-danger"
        *ngIf="
          userForm.value.password !== userForm.value.confirmPassword &&
          userForm.controls['confirmPassword'].touched &&
          userForm.value.confirmPassword
        "
        >Los valores deben de ser iguales</span
      >
    </div>
    <div class="col-12 col-md-6 mt-3">
      <label for="first_name">Nombre:</label>
      <input
        type="text"
        formControlName="first_name"
        class="form-control"
        id="first_name"
      />
    </div>
    <div class="col-12 col-md-6 mt-3">
      <label for="last_name">Apellido:</label>
      <input
        type="text"
        formControlName="last_name"
        class="form-control"
        id="last_name"
      />
    </div>
    <div class="col-12 col-md-6 mt-3">
      <label for="phone_number">Numero de telefono:</label>
      <input
        type="text"
        formControlName="phone_number"
        class="form-control"
        id="phone_number"
        maxlength="15"
      />
      <small class="text-gray"
        >Debe de tener un formato ( {{ "+520000000000" | phone }} )</small
      >
    </div>
    <div class="col-12 col-md-6 mt-3">
      <label for="code">Codigo de referencia:</label>
      <input
        type="text"
        formControlName="code"
        class="form-control"
        id="code"
      />
    </div>
  </div>
  <button
    type="submit"
    class="btn btn-primary mt-2 d-block w-100 mt-5 d-flex justify-content-center align-items-center"
    [disabled]="!userForm.valid"
  >
    <!--   @if (loading_petition) {
      <div
        class="spinner-border"
        role="status"
        style="height: 20px; width: 20px"
      >
        <span class="visually-hidden"></span>
      </div>
    } -->

    <b class="mx-3"> ¡Guardar información!</b>
  </button>
</form>
