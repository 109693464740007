import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PaymentMethod,
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { StripeCardComponent, injectStripe } from 'ngx-stripe';
import { AuthService } from '../../services/auth.service';
import { suscriptions, suscription } from '../../pages/register/const';
import { GlobalFunctionService } from '../../services/function.service';
import { PaymentService, tier } from '../../services/payment.service';
import { environment } from '../../../../environments/environment';
import {
  PaymentMethodWithDefault,
  User,
} from '../../../core/interfaces/interfaces.interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-card',
  templateUrl: './card.html',
  styleUrls: ['./card.scss'],
})
export class CardComponent implements OnInit {
  card: FormGroup = new FormGroup({
    holder: new FormControl('', [Validators.required]),
  });
  @Input() user: User | null = null;
  @Input() suscription_selected: suscription | undefined = undefined;
  @Input() register = false;

  packageSelected: any;

  @ViewChild(StripeCardComponent) cardView!: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#000',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '20px',
        '::placeholder': {
          color: '#FFFFFF',
        },
      },
    },
    hidePostalCode: true,
  };
  cardsList: Array<PaymentMethodWithDefault> = [];
  new_card = false;
  @Output() submit_card = new EventEmitter();

  stripe = injectStripe(environment.PK_STRIPE);
  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };
  secret = null;
  loading_submit = false;
  loading_payments = false;
  view_suscription = false;
  status_suscription:
    | 'active'
    | 'past_due'
    | 'canceled'
    | 'unpaid'
    | 'paused'
    | 'incomplete'
    | 'incomplete_expired' = 'incomplete';
  constructor(
    public router: ActivatedRoute,
    public authService: AuthService,
    public functions: GlobalFunctionService,
    public paymentService: PaymentService,
    private modalService: NgbModal,
    public route: Router,
  ) {}

  ngOnInit(): void {
    if (this.user) {
      this.selected_suscription();
      this.paymentService.get_payment_client_secret().subscribe((resp) => {
        this.elementsOptions.clientSecret = resp.customer_secret;
        this.getpaymentsMethods();
      });
    }
  }

  selected_suscription() {
    this.paymentService.get_tier_data().subscribe((resp) => {
      if (
        this.user &&
        this.user.metadata &&
        this.user.metadata.tier &&
        this.user.metadata.tier.name
      ) {
        if (this.user.metadata) {
          /* yase que se puede mejorar toy cansado xd  */
          const name = this.user.metadata.tier.name;
          if (name) {
            const suscription = suscriptions.find(
              (sus) => sus.title.toLowerCase() === name.toLowerCase(),
            );
            const price = resp.find(
              (res) => res.name.toLowerCase() === name.toLowerCase(),
            );
            if (suscription && price) {
              this.suscription_selected = {
                ...suscription,
                price: price.price_with_decimal,
              };
            }
          }
        }
      }
      if (this.user?.metadata) {
        this.paymentService.get_my_subscription().subscribe((resp) => {
          const indexSuscription = resp.findIndex(
            (item) => item.status === 'active' || item.status === 'incomplete',
          );
          if (indexSuscription >= 0) {
            this.status_suscription = resp[indexSuscription].status;
            this.view_suscription = resp[indexSuscription].status === 'active';
          } else {
            if (resp.length > 0) {
              const all_activated = resp.every(
                (item) => item.status !== 'active',
              );
              if (!this.register && all_activated) {
                this.route.navigateByUrl('/client/seleccionar_paquete');
              }
            }
          }
        });
      }
    });
  }
  getpaymentsMethods() {
    this.loading_payments = true;
    this.paymentService.get_payments_methods().subscribe((resp) => {
      this.cardsList = resp;
      this.new_card = resp.length > 0;
      this.loading_payments = false;
    });
  }

  submitCard = async () => {
    this.loading_submit = true;

    const { holder } = this.card.value;

    let name = {
      name: holder,
    };

    if (this.cardView && this.elementsOptions.clientSecret) {
      this.stripe
        .confirmCardSetup(this.elementsOptions.clientSecret, {
          payment_method: {
            card: this.cardView.element,
            billing_details: name,
          },
        })
        .subscribe((card_setup: any) => {
          const submit = () => {
            this.loading_submit = false;
            this.getpaymentsMethods();
            this.submit_card.emit(card_setup);
          };

          if (card_setup.error) {
            this.functions.alertAnimation(
              'error',
              card_setup.error.message,
              'ERROR',
            );
            this.loading_submit = false;
          } else {
            setTimeout(() => {
              /* Obtener la tarjeta  */
              this.paymentService
                .get_stripe_payment_method({
                  stripe_id: card_setup.setupIntent.payment_method,
                })
                .subscribe((card) => {
                  if (card) {
                    if (this.user?.is_free_user) {
                      submit();
                    } else {
                      this.paymentService
                        .get_my_subscription()
                        .subscribe((subs) => {
                          if (subs.length == 0) {
                            this.paymentService
                              .set_as_default(card.id, card)
                              .subscribe((resp) => {
                                let obj = {
                                  ['Plata']: 0,
                                  ['Oro']: 1,
                                  ['Platino']: 2,
                                };
                                if (this.suscription_selected) {
                                  const index =
                                    this.suscription_selected?.title;
                                  if (index) {
                                    this.paymentService
                                      .create_subscription({
                                        tier: obj[index],
                                      })
                                      .subscribe((resp) => {
                                        this.functions.updateSuscription(true);

                                        this.functions.alertAnimation(
                                          'success',
                                          'Se ha suscrito correctamente la tarjeta',
                                          'Enhorabuena',
                                        );
                                        submit();
                                      });
                                  }
                                }
                              });
                            // seleccionar la tarjeta y suscribirse
                          } else {
                            const status = this.functions.validate_status(subs);
                            this.functions.updateSuscription(status);

                            this.functions.alertAnimation(
                              'success',
                              'Se ha creado correctamente la tarjeta',
                              'Enhorabuena',
                            );
                            submit();
                          }
                        });
                    }
                  }
                });
            }, 3000);

            /* Buscar suscripcion */
          }
        });
    }
  };

  changeCard(event: any) {}
  validate_color(plan: 0 | 1 | 2 | '') {
    return this.functions.validate_color(plan);
  }
  deletePayments = async (item: PaymentMethodWithDefault) => {
    let text = `¿Seguro que quieres eliminar este metodo de pago?`;
    if (item.default === 'True') {
      text = `Al eliminar esta tarjeta, tu plan actual 
      se cancelará y necesitarás realizar un nuevo pago 
      para continuar con nuestros servicios.`;
    }
    const bool = await this.functions.optionSwalAlert(
      'Eliminar este metodo de pago',
      text,
    );
    if (bool) {
      this.paymentService.delete_payments(item.id).subscribe((resp) => {
        const findPayment = this.cardsList.findIndex(
          (card) => card.id === item.id,
        );
        if (findPayment >= 0) {
          this.cardsList.splice(findPayment, 1);
        }
        if (this.cardsList.length === 0) {
          this.new_card = false;
        }
      });
    }
  };

  set_as_default = async (card: PaymentMethod, index: number) => {
    this.paymentService.get_my_subscription().subscribe((resp) => {
      const indexSuscription = resp.findIndex(
        (item) => item.status == 'active' || item.status == 'incomplete',
      );

      if (indexSuscription >= 0) {
        const tier = resp[indexSuscription].tier;
        const find = suscriptions.find((sus) => sus.id === tier);
        if (find) {
          this.suscription_selected = find;
        }
      }
    });
    const bool = await this.functions.optionSwalAlert(
      'Configuración de tarjeta',
      '¿Seguro que quieres configurar esta targeta como principal?',
    );
    if (bool) {
      const validate_default = () => {
        const def = this.cardsList.findIndex((item) => item.default === 'True');
        if (def >= 0) {
          this.cardsList[def].default = 'False';
        }
      };

      this.paymentService.set_as_default(card.id, card).subscribe(
        (resp) => {
          this.functions.alertAnimation(
            'success',
            'Se ha configurado la tarjeta correctamente',
            'Enhorabuena',
          );

          validate_default();
          this.cardsList[index].default = 'True';
          if (this.suscription_selected) {
            this.paymentService.get_my_subscription().subscribe((resp) => {
              if (resp.length == 0) {
                const id = this.suscription_selected
                  ? this.suscription_selected.id
                  : false;
                if (id !== false) {
                  this.paymentService
                    .create_subscription({ tier: id })
                    .subscribe((resp) => {});
                }
              }
            });
          }
        },
        (err) => {
          this.cardsList[index].default = this.cardsList[index].default;
        },
      );
    }
  };
  changeView = () => {
    this.new_card = !this.new_card;
    if (this.new_card) {
      this.getpaymentsMethods();
    }
  };
}
