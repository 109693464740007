<div class="text-center">
  <h1>¡Forma de pago!</h1>
  <hr />
  @if (user) {
    <app-card
      [user]="user"
      (submit_card)="submitForm()"
      [suscription_selected]="suscription_selected"
      [register]="true"
    ></app-card>
  }
</div>
