@if (user) {
  <div style="height: 70vh">
    <ngx-graph
      [links]="links"
      [nodes]="nodes"
      [curve]="curve"
      [layout]="layout"
      [nodeWidth]="350"
      [layoutSettings]="layoutSettings"
      [enableZoom]="true"
      [showMiniMap]="true"
      [autoZoom]="true"
      [autoCenter]="autoCenter"
      [center$]="center$"
      [update$]="update$"
    >
      <ng-template #defsTemplate>
        <svg:marker
          id="arrow"
          viewBox="0 -5 10 10"
          refX="8"
          refY="0"
          markerWidth="4"
          markerHeight="4"
          orient="auto"
        >
          <svg:path d="M0,-5L10,0L0,5" class="arrow-head" />
        </svg:marker>
      </ng-template>

      <ng-template #linkTemplate let-link>
        <svg:g class="edge">
          <svg:path
            class="line"
            stroke-width="2"
            marker-end="url(#arrow)"
          ></svg:path>
          <svg:text class="edge-label" text-anchor="middle">
            <textPath
              class="text-path"
              [attr.href]="'#' + link.id"
              [style.dominant-baseline]="link.dominantBaseline"
              startOffset="50%"
            >
              {{ link.label }}
            </textPath>
          </svg:text>
        </svg:g>
      </ng-template>

      <!-- Cuerpos principales -->
      <ng-template #nodeTemplate let-node>
        <ng-container *ngIf="node.id === '1'; else cardPermissions">
          <svg:g
            class="node"
            xmlns="http://www.w3.org/2000/xhtml"
            width="400"
            height="100"
          >
            <svg:foreignObject width="500" height="100">
              <div class="cardContainer">
                <div class="d-flex justify-content-center" *ngIf="node.data">
                  <div class="mr-4">
                    <div
                      class="img-user"
                      [style.background-image]="
                        'url(' + 'assets/images/logo_green.png' + ')'
                      "
                    ></div>
                  </div>
                  <div class="d-flex flex-column justify-content-center gap-3">
                    @if (user) {
                      <div class="d-flex gap-2 align-items-center">
                        <label
                          >{{ user.first_name }} {{ user.last_name }}</label
                        >
                        <em
                          class="fa fa-circle"
                          [ngClass]="{ 'text-success': user.is_active }"
                        ></em>
                      </div>
                    }
                    <label>Usuarios: {{ node.data.users_length }}</label>
                  </div>
                </div>
              </div>
            </svg:foreignObject>
          </svg:g>
        </ng-container>
        <ng-template #cardPermissions>
          <svg:g class="node" xmlns="http://www.w3.org/2000/xhtml">
            <svg:foreignObject
              width="300"
              [attr.height]="validateHeight(node.data)"
            >
              <xhtml:div class="card-permissions">
                <div
                  class="body d-flex flex-column justify-content-between h-100"
                >
                  <div class="gap-3 d-flex flex-column p-3">
                    <div class="d-flex gap-2 justify-content-between align-items-center">
                      <label
                      style="font-size: 19px;"
                        >{{ node.data.user.first_name }}
                        {{ node.data.user.last_name }}</label
                      >
                      <em
                        class="fa fa-circle"
                        style="font-size: 30px;"
                        [ngClass]="{ 'text-success': node.data?.is_active }"
                      ></em>
                    </div>
                    <small>{{ node.data.user.phone_number | phone }}</small>
                    <small>{{ node.data.user.email }}</small>
                  </div>
                  <div
                    class="bg-secondary p-3 text-center"
                    [class]="
                      node.data.user.week_paid_status
                        ? 'bg-secondary'
                        : 'bg-danger'
                    "
                    style="border-radius: 0px 0px 8px 8px"
                  >
                    {{
                      node.data.user.week_paid_status
                        ? "Pendiente"
                        : "Realizado"
                    }}
                  </div>
                </div>
              </xhtml:div>
            </svg:foreignObject>
          </svg:g>
        </ng-template>
      </ng-template>
    </ngx-graph>
  </div>
}
