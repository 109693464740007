import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-step-3',
  templateUrl: './step3.html',
  styleUrls: ['../register.scss'],
})
export class Step3Component implements OnInit {
  @Output() submit = new EventEmitter<{ step: 3 }>();
  conditions = '';
  privacity = '';
  constructor(public authService: AuthService) {}

  ngOnInit(): void {
    this.authService.getFileTxt('aviso_de_privacidad').subscribe((resp) => {
      this.privacity = resp;
    });
    this.authService.getFileTxt('terminos_y_condiciones').subscribe((resp) => {
      this.conditions = resp;
    });
 
  }

  submitForm() {
    this.submit.emit({ step: 3 });
  }
}
