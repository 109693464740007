import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  ImageCroppedEvent,
  ImageTransform,
  LoadedImage,
} from 'ngx-image-cropper';

/* https://github.com/Mawi137/ngx-image-cropper/blob/master/README.md */
@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.html',
  styleUrls: ['./image-cropper.scss'],
})
export class ImageCropperComponent implements OnInit {
  @Input('url') url: any = '';
  @Output('setbase64') setbase64 = new EventEmitter();
  @Input() type = 'base64';
  @Input() size = 1;
  @Input() rounded = false;
  @Input() aspectRatio = 1 / 1;
  @Input() roundCropper = false;
  @Input() cropperStaticHeight = 350;
  @Input() cropperStaticWidth = 350;

  @Input() imageBase64 = '';
  @Input() preview = ''

  containWithinAspectRatio = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  file: any;

  defaultImage: String = 'assets/images/default.png';
  showCropp = false;
  canvasRotation = 0;
  scale = 0;
  transform: ImageTransform = {
    scale: 0,
    flipH: false,
    flipV: false,
  };

  constructor() {}

  ngOnInit(): void {}
  cropperReady(event: any) {}

  fileChangeListener(event: any) {
    this.file = event.target.files[0];
    this.imageChangedEvent = event;
    this.showCropp = true;
  }

  imageCropped = async (event: ImageCroppedEvent) => {
    if (this.type === 'base64' && event.blob) {
      const data = await this.convertFileToBase64(event.blob);
      this.url = data;
      this.setbase64.emit(data);
    }
  };
  loadImageFailed() {}

  LoadedImage(event: any) {}

  convertFileToBase64 = (blob: Blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };
  convertBase64ToFile(base64Image: any) {
    const split = base64Image.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = atob(split[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }
  checkImage = async (url: string) => {
    const res = await fetch(url);
    const buff = await res.blob();
    return buff.type.startsWith('image/');
  };

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }
  imageLoaded() {}
  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }
  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }
  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }
  returnImage() {
    return this.url ? this.url : 'assets/images/default.png';
  }
}
