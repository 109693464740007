import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  NewUser,
  User,
} from '../../../../core/interfaces/interfaces.interfaces';
import { UsersService } from '../../../services/users.service';
import { GlobalFunctionService } from '../../../services/function.service';

@Component({
  selector: 'app-step-1',
  templateUrl: './step1.html',
  styleUrls: ['../register.scss'],
})
export class Step1Component implements OnInit {
  @Output() submit = new EventEmitter<{ step: 1; user: NewUser | undefined }>();
  @Input() user: NewUser | undefined;
  @Input() code: string = '';
  @Input() is_admin: boolean = false;

  userForm: FormGroup = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    confirmPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone_number: new FormControl('', [Validators.required]),
    code: new FormControl(''),
    is_free_user: new FormControl(false),
  });
  view_password: boolean = false;
  constructor(
    public userService: UsersService,
    public functions: GlobalFunctionService,
  ) {}

  ngOnInit(): void {
    if (this.code) {
      this.userForm.controls['code'].setValue(this.code);
      this.userForm.controls['code'].disable();
    }
    if (this.user) {
      this.userForm.setValue({
        ...this.user,
        code: this.code ? this.code : this.user.code,
        confirmPassword: this.user.password,
      });
    }
    // Suscribirse a cambios en los campos de contraseña para validar su igualdad
    const passwords = ['password', 'confirmPassword'];

    passwords.forEach((item) => {
      this.userForm.controls[item].valueChanges.subscribe(() => {
        this.validatePasswords();
      });
    });
  }
  onSubmit() {
    const {
      password,
      first_name,
      last_name,
      email,
      phone_number,
      code,
      is_free_user,
    } = this.userForm.value;
    this.submit.emit({
      step: 1,
      user: {
        password,
        first_name,
        last_name,
        email,
        phone_number,
        code,
        is_free_user,
      },
    });
  }

  validatePasswords() {
    const password = this.userForm.controls['password'].value;
    const confirmPassword = this.userForm.controls['confirmPassword'].value;
    if (password !== confirmPassword) {
      this.userForm.controls['confirmPassword'].setErrors({ notSame: true });
    } else {
      this.userForm.controls['confirmPassword'].setErrors(null);
    }
  }
}
