import { Component, ElementRef, OnInit } from '@angular/core';
import { success_cases } from '../../../core/interfaces/interfaces.interfaces';
import { SuccessCasesService } from '../../services/success_cases.service';

@Component({
  selector: 'app-carousel-about',
  templateUrl: './carousel-about.html',
  styleUrls: ['./carousel-about.scss'],
})
export class CarouselAboutComponent implements OnInit {
  list: carouselAbout[] = [];
  temporal: carouselAbout[] = [];
  limit = 0;
  currentIndex: number = 0;
  items = 3;

  selectedUsers: carouselAbout[] = [];

  contenedor: HTMLElement | null = null;
  loading_animation = true;
  constructor(
    private elementRef: ElementRef,
    public successCasesService: SuccessCasesService,
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  ngAfterViewInit(): void {
    this.contenedor = this.elementRef.nativeElement.querySelector('#middle');
    if (!this.contenedor) {
      console.error("No se encontró el contenedor con el ID 'middle'");
    }
  }

  mostrarElementoActual = (animationIn: string) => {
    this.loading_animation = false;
    const items = this.items;
    const current = this.currentIndex;

    /* 0,3 4,7 8, 12*/
    const indiceInicio = current * items;
    const indiceFin = indiceInicio + items;

    this.temporal = this.list.slice(indiceInicio, indiceFin);

    if (this.contenedor) {
      this.contenedor.classList.add('animate__animated', animationIn);
      setTimeout(() => {
        if (this.contenedor) {
          this.contenedor.classList.remove('animate__animated', animationIn);
        }
        this.loading_animation = true;
      }, 1000); // Ajusta el tiempo para que coincida con la duración de la animación
    }
  };
  next(): void {
    this.currentIndex = this.currentIndex + 1;
    if (this.currentIndex === this.limit) {
      this.currentIndex = 0;
    }
    this.mostrarElementoActual('animate__fadeInRight');
  }

  previous(): void {
    if (this.currentIndex === 0) {
      this.currentIndex = this.limit - 1;
    } else {
      this.currentIndex = this.currentIndex - 1;
    }
    this.mostrarElementoActual('animate__fadeInLeft');
  }
  getData() {
    this.successCasesService.success_cases(``).subscribe((resp) => {
      this.list = resp;
      this.limit = Math.ceil(this.list.length / this.items);
      this.temporal = this.list.slice(0, this.items);
    });
  }
  validateImageError(entity: any) {
    entity.imageUrl = 'assets/images/default.png';
  }
}

export interface carouselAbout extends success_cases {
  imageHeight?: string;
}
