<div class="text-center mt-5">
  <h1>¡Registrate!</h1>
  <p>Aviso de privacidad integral</p>
</div>
@if (privacity) {
  <div class="content-text">
    {{ privacity }}
  </div>
}

<div class="text-center mt-5">
  <p>Terminos y condiciones</p>
</div>

@if (conditions) {
  <div class="content-text">
    {{ conditions }}
  </div>
}

<div class="text-center">
  <button type="button" class="btn btn-primary mt-3" (click)="submitForm()">
    Acepto los terminos y condiciones
  </button>
</div>
