import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Uris } from './Uris';
import { Observable, map } from 'rxjs';
import { Router } from '@angular/router';
import { success_cases } from '../../core/interfaces/interfaces.interfaces';
import { CACHING_ENABLED } from '../../core/interceptors/headers.handler.interceptor';

@Injectable({
  providedIn: 'root',
})
export class SuccessCasesService {
  constructor(
    private http: HttpClient,
    public router: Router,
  ) {}

  success_cases(query: any): Observable<Array<success_cases>> {
    return this.http
      .get<
        Array<success_cases>
      >(`${Uris.SUCCESS_CASES}/${query}`, { context: new HttpContext().set(CACHING_ENABLED, false) })
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }
  create_success_cases(body: success_cases): Observable<success_cases> {
    return this.http.post<success_cases>(`${Uris.SUCCESS_CASES}/`, body).pipe(
      map((resp) => {
        return resp;
      }),
    );
  }

  delete_success_cases(id: any): Observable<any> {
    return this.http.delete<any>(`${Uris.SUCCESS_CASES}/${id}/`).pipe(
      map((resp) => {
        return resp;
      }),
    );
  }
  success_cases_by_id(id: number): Observable<success_cases> {
    return this.http.get<success_cases>(`${Uris.SUCCESS_CASES}/${id}/`).pipe(
      map((resp) => {
        return resp;
      }),
    );
  }
  edit_success_cases_by_id(
    id: number,
    body: success_cases,
  ): Observable<success_cases> {
    return this.http
      .patch<success_cases>(`${Uris.SUCCESS_CASES}/${id}/`, body)
      .pipe(
        map((resp) => {
          return resp;
        }),
      );
  }
}
