@switch (step) {
  @case (1) {
    <app-step-1
      (submit)="submitForm($event)"
      [user]="user"
      [code]="code"
      [is_admin]="is_admin"
    ></app-step-1>
  }
  @case (2) {
    <app-step-2
      (submit)="submitForm($event)"
      [suscription]="suscription"
    ></app-step-2>
  }
  @case (3) {
    <app-step-3 (submit)="submitForm($event)"></app-step-3>
  }
  @case (4) {
    @if (user_loged) {
      <app-step-4
        (submit)="submitForm($event)"
        [user]="user_loged"
        [suscription_selected]="suscription"
      ></app-step-4>
    }
  }
  @default {}
}

<div class="d-flex justify-content-center align-items-center mt-4">
  @for (item of steps; track step) {
    <div class="form-check">
      <input
        class="form-check-input"
        type="radio"
        name="steps"
        [id]="'step' + item"
        [checked]="step === item"
        (change)="step = item"
        [disabled]="validateDisabledCheck(item)"
      />
      <label class="form-check-label" [for]="'step' + item"></label>
    </div>
  }
</div>
